import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Divider, Table, Tag } from "antd";
import {
  Flex,
  Badge,
  SimpleGrid,
  GridItem,
  IconButton,
} from "@chakra-ui/react";
import { getOrder } from "../../features/order/orderSlice";
import BillSystem from "./BillSystem";
import Loading from "../../components/Loading";
import { useLocation } from "react-router-dom";
import { buildAnswersTree, convertFromInvoice } from "../../utils/Tools";
import Aligner from "../../components/order/Aligner";
import {
  deleteInvoice,
  markAsPaid,
  markAsUnPaid,
  setInvoice,
} from "../../features/invoice/InvoiceSlice";
import { DownOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import { FaTrash } from "react-icons/fa";
import { getClinic } from "../../features/user/UserSlice";
const AccountantOrder = () => {
  const location = useLocation();
  let id = location.state?.id;
  const dispatch = useDispatch();
  const { order, isLoading } = useSelector((store) => store.order);
  let hasInvoice = order.hasInvoice;
  const { orderLabels, orderType, roleText, heading, buttonsText } =
    useSelector((store) => store.lang);
  const { user, clinic } = useSelector((store) => store.user);
  function formatGermanDate(dateString) {
    return dateString.replace(/(\d{1,2})\/(\d{1,2})\/(\d{4})/, "$1.$2.$3");
  }
  useEffect(() => {
    dispatch(setInvoice(undefined));
    dispatch(getOrder(id));
  }, []);
  const { invoice, invoiceExist } = useSelector((store) => store.invoice);
  const { order: curOrder } = useSelector((store) => store.order);
  useEffect(() => {
    dispatch(getOrder(id));
    if (order.id !== 1) dispatch(setInvoice(order.invoice));
  }, [invoiceExist]);

  useEffect(() => {
    if (order.id !== 1) dispatch(setInvoice(order.invoice));
  }, [order.hasInvoice]);
  useEffect(() => {
    if (order.id !== 1) {
      dispatch(setInvoice(order.invoice));
      dispatch(getClinic(order.clinic.Id));
    }
  }, [order.id, clinic.id]);
  const { language } = useSelector((store) => store.lang);
  function getDate(date) {
    let utcTime = new Date(date);
    let timezoneOffset = new Date().getTimezoneOffset();

    // Convert UTC time to local time
    let localTime = new Date(utcTime.getTime() - timezoneOffset * 60 * 1000);
    const dateObject = new Date(localTime);

    const formattedDate = dateObject.toLocaleDateString("en-GB");

    // Format the time as "hh:mm a"
    const formattedTime = dateObject.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return { formattedDate, formattedTime };
  }

  let info_ = [];
  if (hasInvoice) {
    info_ = convertFromInvoice(invoice.itmes);
  }
  const [info, setInfo] = useState(info_);
  if (info_.length !== info.length) {
    setInfo(info_);
  }

  if (isLoading) return <Loading />;
  let createdDate = getDate(order.createdDate);
  let delivredDate = getDate(order.deliveredDate);
  let invoiceCreatedDate = getDate(invoice.createdAt);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
    },
  ];
  let data = [
    {
      key: "1",
      title: roleText.clinic,
      data: order.clinic.Name,
    },
    {
      key: "2",
      title: orderLabels.firstName,
      data: order.patient.firstName,
    },
    {
      key: "3",
      title: orderLabels.lastName,
      data: order.patient.lastName,
    },
    {
      key: "4",
      title: orderLabels.id,
      data: order.patient.medicalRecordNumber,
    },
    {
      key: "5",
      title: orderLabels.birthDate,
      data: order.patient.dateOfBirth,
    },
    {
      key: "6",
      title: heading.theProducts,
      data: order.product.map((e) => (
        <Tag color="geekblue"> {e.product.name.en}</Tag>
      )),
    },
    {
      key: "7",
      title: orderLabels.delivredDate,
      data: (
        <Tag color="geekblue" style={{ alignSelf: "end" }}>
          {delivredDate?.formattedDate + " " + delivredDate?.formattedTime}
        </Tag>
      ),
    },
    {
      key: "8",
      title: orderLabels.note,
      data: order.note,
    },
  ];

  const isPaid = invoice.isPaid;
  const paymentId = order?.invoice?.id;
  return (
    <Flex flexDir={"column"} paddingTop={"1rem"}>
      <Flex gap={"1rem"} marginBottom={"1rem"}>
        <Badge
          rounded={"full"}
          color={order.isUrgent ? "#821D30" : "#1E5162"}
          fontSize={"sm"}
          paddingX={"0.75rem"}
          alignSelf={"center"}
          bg={order.isUrgent ? "#FAE8E0" : "#D4F1F4"}
          textTransform={"hidden"}
        >
          {createdDate.formattedDate + " " + createdDate.formattedTime}
        </Badge>
        <Badge
          rounded={"full"}
          color={order.isUrgent ? "#821D30" : "#1E5162"}
          fontSize={"sm"}
          paddingX={"0.75rem"}
          alignSelf={"center"}
          bg={order.isUrgent ? "#FAE8E0" : "#D4F1F4"}
          textTransform={"hidden"}
        >
          {order.isUrgent ? orderType.urgent : orderType.normal}
        </Badge>

        {isPaid && (
          <Badge
            color={"green.800"}
            width={"6rem"}
            bg={"green.200"}
            paddingX={"0.75rem"}
            alignSelf={"center"}
            rounded={"full"}
            letterSpacing={"2px"}
            textAlign={"center"}
          >
            Paid
          </Badge>
        )}

        {hasInvoice && (isPaid === false || user.role === "Admin") && (
          <Flex minW={"10rem"} justifyContent={"end"}>
            <Button
              onClick={() => {
                isPaid
                  ? dispatch(markAsUnPaid(paymentId))
                  : dispatch(markAsPaid(paymentId));
              }}
            >
              {isPaid ? buttonsText.markAsUnPaid : buttonsText.markAsPaid}
            </Button>
          </Flex>
        )}
      </Flex>
      <SimpleGrid columns={5}>
        <GridItem colSpan={[5, 5, 5]}>
          <Table pagination={false} columns={columns} dataSource={data} />
        </GridItem>
        <GridItem colSpan={5}>
          <Divider />
        </GridItem>

        <Tree
          style={{
            padding: ".5rem",
            borderRadius: "4px",
          }}
          showLine
          switcherIcon={<DownOutlined />}
          defaultExpandAll={true}
          treeData={buildAnswersTree(
            curOrder.product,
            curOrder.answerIds,
            language
          )}
        />
        {order.hasAligner === true ? (
          <>
            <Aligner
              alignerCount1={order.alginerLower}
              alignerCount2={order.alginerUpper}
              role={"Accountant"}
              id={order.id}
            ></Aligner>
          </>
        ) : (
          ""
        )}
      </SimpleGrid>
      <Divider />
      <BillSystem
        hasInvoice={hasInvoice}
        isPaid={isPaid}
        info={info}
        id={id}
        medId={order.patient.medicalRecordNumber}
        clinicName={order.clinic.Name}
        fullLocation={order.clinic.fullLocation}
        secondLocation={clinic.secondLocation}
        delivredDate={formatGermanDate(delivredDate.formattedDate)}
        createdDate={formatGermanDate(invoiceCreatedDate.formattedDate)}
        code={invoice.code}
      />
    </Flex>
  );
};
export default AccountantOrder;
