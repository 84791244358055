import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, InputNumber, Radio, Table } from "antd";
import {
  CardHeader,
  Card,
  Flex,
  Text,
  Img,
  IconButton,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { convertToInvoice, generateUUID } from "../../utils/Tools";
import { FaFilePdf } from "react-icons/fa";
import html3pdf from "html3pdf"; // Updated import statement
import img from "../../assets/images/t.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteInvoice,
  postInvoice,
  updateInvoice,
} from "../../features/invoice/InvoiceSlice";
import Loading from "../../components/Loading";
import { FaTrash } from "react-icons/fa";
function formatGermanCurrency(number) {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}
function BillSystem({
  isPaid,
  info,
  id,
  medId,
  clinicName,
  fullLocation,
  delivredDate,
  createdDate,
  code,
  hasInvoice,
  secondLocation,
}) {
  const [componentSize, setComponentSize] = useState("default");
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const { user } = useSelector((store) => store.user);
  const [mainBill, setMainBill] = useState(true);
  const dispatch = useDispatch();
  const [items, setItems] = useState(info);
  const [tax, setTax] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const deleteHandler = (id) => {
    setItems(items.filter((e) => e.id !== id));
  };
  const { invoiceIsLoading, invoice } = useSelector((store) => store.invoice);
  useEffect(() => {
    setItems(info);
    if (info.length) {
      setTax(info[0]?.invoiceFeePercentage);
    }
  }, [info.length]);
  const [item, setItem] = useState({
    id: "",
    product: "",
    quantity: 0,
    string: "",
    price: 0,
    total: 0,
    invoiceFeePercentage: 0,
  });

  const nameHandler = (e) => {
    setItem({ ...item, product: e.target.value });
  };

  const quantityHandler = (e) => {
    setItem({
      ...item,
      quantity: +e,
      total: item.price * e,
    });
  };

  const stringHandler = (e) => {
    setItem({ ...item, string: e.target.value });
  };

  const PriceHandler = (e) => {
    setItem({
      ...item,
      price: +e,
      total: item.quantity * e,
    });
  };
  const taxHandler = (e) => {
    setItem({
      ...item,
      invoiceFeePercentage: +e,
    });
    setTax(+e);
  };
  const addItem = () => {
    const newItem = { ...item, id: generateUUID() };
    setItems([...items, newItem]);
    setItem({
      id: "",
      product: "",
      quantity: 0,
      string: "",
      price: 0,
      total: 0,
      invoiceFeePercentage: 0,
    });
    // console.log(items);
  };
  const submitHandler = () => {
    if (items.length === 0) {
      return;
    }
    const newItems = items.map((ele) => {
      return { ...ele, invoiceFeePercentage: tax };
    });
    // console.log(newItems);
    let data = convertToInvoice(newItems, id);
    if (hasInvoice) {
      const udata = {
        data: data,
        id: invoice.id,
      };
      dispatch(updateInvoice(udata));
    } else {
      dispatch(postInvoice(data));
    }
    // onOpen();
  };
  const saveAsPDF = () => {
    const input = document.getElementById("pdfContent");
    html3pdf()
      .from(input)
      .set({
        filename: "download.pdf",
        image: { type: "jpeg", quality: 1.0 },
        html2canvas: {
          scale: 4, // Increase the scale factor for better quality
          useCORS: true, // Enable cross-origin resource sharing
          logging: true,
          letterRendering: true,
        },
        jsPDF: {
          unit: "pt",
          format: "a4",
          orientation: "portrait",
        },
      })
      .save();
  };
  const { buttonsText } = useSelector((store) => store.lang);
  return (
    <Flex flexDir={"column"}>
      <Flex gap={"1rem"}>
        {(hasInvoice || invoice.temp) && (
          <Menu>
            <MenuButton
              w={"6rem"}
              as={IconButton}
              aria-label="Options"
              icon={<FaFilePdf />}
              variant="outline"
              marginBottom={"1rem"}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  setMainBill(true);
                  onOpen();
                }}
              >
                {buttonsText.invoice}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMainBill(false);
                  onOpen2();
                }}
              >
                {buttonsText.deliveryNote}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        {hasInvoice && user.role === "Admin" && (
          <IconButton
            icon={<FaTrash />}
            variant={"outline"}
            w={"6rem"}
            colorScheme="red"
            onClick={() => {
              // console.log(invoice.id);
              dispatch(deleteInvoice(invoice.id));
            }}
          />
        )}
      </Flex>
      {isPaid || (
        <Flex width={"100%"}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            initialValues={{ size: componentSize }}
            onValuesChange={onFormLayoutChange}
            size={componentSize}
            style={{ width: "50%" }}
          >
            <Form.Item label="Form Size" name="size">
              <Radio.Group>
                <Radio.Button value="small">Small</Radio.Button>
                <Radio.Button value="default">Default</Radio.Button>
                <Radio.Button value="large">Large</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Bezeichnung">
              <Input onChange={nameHandler} value={item.product} />
            </Form.Item>
            <Form.Item label="Menge">
              <InputNumber onChange={quantityHandler} value={item.quantity} />
            </Form.Item>
            <Form.Item label="Einheit">
              <Input onChange={stringHandler} value={item.string} />
            </Form.Item>
            <Form.Item label="Einzel €">
              <InputNumber onChange={PriceHandler} value={item.price} />
            </Form.Item>
            <Divider />
            <Form.Item label="Umsatzsteuer">
              <InputNumber onChange={taxHandler} value={tax} />
            </Form.Item>

            <Form.Item label="Gesamt €">
              <Text fontWeight={"bold"}>{item.total}</Text>
            </Form.Item>
            <Form.Item label="New Item">
              <Button onClick={addItem}>Add</Button>
            </Form.Item>
            <Form.Item label={buttonsText.save}>
              <Button onClick={submitHandler}>{buttonsText.submit}</Button>
            </Form.Item>
          </Form>

          <Card width={"50%"}>
            <CardHeader>
              <MyTable
                price={items.reduce((acc, curr) => acc + curr.total, 0)}
                tax={tax}
              />
            </CardHeader>
          </Card>
        </Flex>
      )}
      <TableOfItems
        isPaid={isPaid}
        items={items}
        deleteHandler={deleteHandler}
      />

      <Modal onClose={onClose} size={"xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            <Button onClick={saveAsPDF} style={{ marginTop: "10px" }}>
              Save as PDF
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {invoiceIsLoading ? (
              <Loading />
            ) : (
              <FinalBill
                delivredDate={delivredDate}
                items={items}
                deleteHandler={deleteHandler}
                fullLocation={fullLocation}
                secondLocation={secondLocation}
                clinicName={clinicName}
                medId={medId}
                mainBill={mainBill}
                createdDate={createdDate}
                code={code}
                tax={tax}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal onClose={onClose2} size={"xl"} isOpen={isOpen2}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            <Button onClick={saveAsPDF} style={{ marginTop: "10px" }}>
              Save as PDF
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {invoiceIsLoading ? (
              <Loading />
            ) : (
              <FinalBill
                delivredDate={delivredDate}
                items={items}
                deleteHandler={deleteHandler}
                fullLocation={fullLocation}
                secondLocation={secondLocation}
                clinicName={clinicName}
                medId={medId}
                mainBill={false}
                createdDate={createdDate}
                code={code}
                tax={tax}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose2}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default BillSystem;

const MyTable = ({ price, tax }) => {
  const dataSource = [
    {
      key: "1",
      name: "Zwischensumme (netto)",
      age: formatGermanCurrency(price),
    },
    {
      key: "2",
      name: "Umsatzsteuer 0 % (aus 0,00 € netto)",
      age: 0,
    },
    {
      key: "3",
      name: `Umsatzsteuer ${tax} % (aus ${price} € netto)`,
      age: formatGermanCurrency((tax * price) / 100),
    },
    {
      key: "4",
      name: "Gesamtbetrag",
      age: formatGermanCurrency(price + (tax * price) / 100),
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "age",
      key: "age",
    },
  ];
  return <Table pagination={false} dataSource={dataSource} columns={columns} />;
};

const TableOfItems = ({
  isPaid,
  items,
  deleteHandler,
  print = false,
  mainBill,
}) => {
  const columns = [
    {
      title: "Bezeichnung",
      dataIndex: "Bezeichnung",
      key: "Bezeichnung",
    },
    {
      title: "Menge",
      dataIndex: "Menge",
      key: "Menge",
    },
    {
      title: "Einheit",
      dataIndex: "Einheit",
      key: "Einheit",
    },
  ];

  if (mainBill || print === false) {
    columns.push({
      title: "Einzel €",
      dataIndex: "Einzel",
      key: "Einzel",
    });
    columns.push({ title: "Gesamt €", dataIndex: "Gesamt", key: "Gesamt" });
  }
  if (print === false) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
    });
  }
  const dataSource = print
    ? items.map((item) => ({
        key: item.id,
        Bezeichnung: item.product,
        Menge: item.quantity,
        Einheit: item.string,
        Einzel: formatGermanCurrency(item.price),
        Gesamt: formatGermanCurrency(item.total),
      }))
    : items.map((item) => ({
        key: item.id,
        Bezeichnung: item.product,
        Menge: item.quantity,
        Einheit: item.string,
        Einzel: formatGermanCurrency(item.price),
        Gesamt: formatGermanCurrency(item.total),
        action: isPaid || (
          <Button onClick={() => deleteHandler(item.id)} danger>
            Delete
          </Button>
        ),
      }));

  return (
    <Table pagination={!print} columns={columns} dataSource={dataSource} />
  );
};

const FinalBill = ({
  delivredDate,
  items,
  deleteHandler,
  fullLocation,
  secondLocation,
  clinicName,
  medId,
  mainBill,
  createdDate,
  code,
  tax,
}) => {
  return (
    <div id="pdfContent">
      <Flex flexDir={"column"} width={"100%"} padding={"1rem"}>
        <Img width={"25%"} alignSelf={"end"} src={img} />
        <Text fontSize={"xs"} alignSelf={"end"}>
          Solutions 4 Medical Practices GmbH
          <br />
          Krefelder Str. 15
          <br /> 41460 Neuss
        </Text>

        <Text fontSize={"small"}>{clinicName}</Text>
        <Text fontSize={"small"}>
          {fullLocation}
          <br />
          {secondLocation}{" "}
        </Text>
        <Text fontSize={"x-large"} fontWeight={"bold"} mb={"1rem"}>
          {mainBill ? "Rechnung" : "Lieferschein"}
        </Text>
        <HeaderTable
          delivredDate={delivredDate}
          medId={medId}
          createdDate={createdDate}
          code={code}
        />
        <Text mb={"1rem"} fontSize={"small"}>
          Unsere Lieferungen/Leistungen stellen wir Ihnen wie folgt in Rechnung.
          <br />
          Diese Rechnung ist sofort fällig und muss innerhalb von 14 Tagen
          beglichen werden.
        </Text>
        <TableOfItems
          items={items}
          deleteHandler={deleteHandler}
          print={true}
          mainBill={mainBill}
        />
        <Divider />
        {mainBill && (
          <MyTable
            price={items.reduce((acc, curr) => acc + curr.total, 0)}
            tax={tax}
          />
        )}
        <Divider />
        <Flex>
          <Text fontSize={"x-small"} width={"30%"}>
            Solutions 4 Medical Practices GmbH <br /> Krefelder Str. 15 <br />
            41460 Neuss
            <br /> Tel.: 02131 1533510
            <br /> Fax: 02131 1533511 info@solutions4mp.com
            <br /> www.solutions4mp.com
          </Text>
          <Text fontSize={"x-small"} width={"30%"}>
            USt-IdNr.: DE328524645 AG Neuss
            <br /> HRB23623
            <br /> Geschäftsführer:
            <br /> Dr. Mahmood Nabaizadeh
          </Text>
          <Text fontSize={"x-small"} width={"30%"}>
            Solutions 4 Medical Practices GmbH
            <br /> Deutsche Apotheker- und Ärztebank
            <br /> eG IBAN: DE29 3006 0601 0033 8072 40
          </Text>
        </Flex>
      </Flex>
    </div>
  );
};

const HeaderTable = ({ delivredDate, medId, code, createdDate }) => {
  const columns = [
    {
      title: "Rechnungsnr",
      dataIndex: "Rechnungsnr",
      key: "Rechnungsnr",
    },
    {
      title: "PAT-Nummer",
      dataIndex: "PAT_Nummer",
      key: "PAT_Nummer",
    },
    {
      title: "Datum",
      dataIndex: "Datum",
      key: "Datum",
    },
    {
      title: "Lieferdatum",
      dataIndex: "Lieferdatum",
      key: "Lieferdatum",
    },
  ];
  const dataSource = [
    {
      key: "1",
      Rechnungsnr: code,
      PAT_Nummer: medId,
      Datum: createdDate,
      Lieferdatum: delivredDate,
    },
  ];

  return <Table pagination={false} columns={columns} dataSource={dataSource} />;
};
