import {
  Divider,
  GridItem,
  SimpleGrid,
  useColorMode,
  Select,
  Flex,
  Button,
  Spacer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PieCharts from "../components/Pie";
import Bars from "../components/Bars";
import Results from "../components/Results";
import LineChart from "../components/LineChart";
import { useDispatch, useSelector } from "react-redux";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  clearProductsFromStats,
  getAligner,
  getStatistics,
} from "../features/statistics/statisticsSlice";
import Loading from "../components/Loading";
import { colors } from "../utils/Tools";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Link } from "react-router-dom";
import ProductsFilter from "../components/product/ProductsFilter";
import { clearTheData } from "../features/products/ProductSlice";
import AlignerStatistics from "../components/AlignerStatistics";

function Stats() {
  const dispatch = useDispatch();
  const { user: me } = useSelector((store) => store.user);
  useEffect(() => {
    if (me === undefined) dispatch(logOut());
  }, [me]);
  const { isLoading, results, bars, products } = useSelector(
    (store) => store.statistics
  );
  const { states, language } = useSelector((store) => store.lang);
  const resultValues =
    results === undefined ? {} : Object.values(results).map((val) => val);
  const resultValues2 = resultValues?.map((val, index) => {
    if (index === 5) return resultValues[6];
    else if (index === 6) return resultValues[5];
    else return val;
  });
  const statuesData = {
    labels: states,
    datasets: [
      {
        data: resultValues2,
        backgroundColor: colors,
      },
    ],
  };
  const contributionData =
    bars === undefined ? {} : Object.values(bars).map((val) => val);
  const data = [];

  for (let i = 0; i < states.length; i++) {
    let index = i;
    if (i === 5) index = 6;
    else if (i === 6) index = 5;
    data.push({ name: states[index], value: resultValues[index] });
  }
  useEffect(() => {
    dispatch(setActivePage(1));
    dispatch(clearTheData());
    dispatch(clearProductsFromStats());
  }, []);
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
  const { colorMode } = useColorMode();

  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [alignerYear, setAlignerYear] = useState(currentYear);
  useEffect(() => {
    dispatch(getStatistics(year));
  }, [year]);
  useEffect(() => {
    dispatch(getAligner(alignerYear));
  }, [alignerYear]);
  // Generate an array of years from 2000 to the current year
  const years = Array.from(
    { length: currentYear - 2023 },
    (_, index) => 2024 + index
  );

  if (isLoading) return <Loading />;
  return (
    <>
      <Results data={data} />
      <Divider />

      <SimpleGrid>
        <Select
          value={year}
          onChange={(e) => {
            if (e.target.value !== year) setYear(e.target.value);
          }}
          w={"30%"}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>

        <GridItem>
          <Bars contributionData={contributionData} />
        </GridItem>
        <GridItem mt={"2rem"}>
          <SimpleGrid
            columns={10}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <GridItem colSpan={[10, 10, 5, 5]}>
              <LineChart colorMode={colorMode} />
            </GridItem>
            <GridItem colSpan={[10, 10, 5, 5]}>
              <PieCharts statuesData={statuesData} colorMode={colorMode} />
            </GridItem>
          </SimpleGrid>
        </GridItem>
        <Divider my={"2rem"} />
        <Select
          value={year}
          onChange={(e) => {
            if (e.target.value !== alignerYear) setAlignerYear(e.target.value);
          }}
          w={"30%"}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
        <AlignerStatistics></AlignerStatistics>
        <Divider />
        <Flex
          marginTop={"1rem"}
          // justifyContent={"space-evenly"}
          w={"100%"}
          flexWrap={"wrap"}
          gap={"1rem"}
        >
          <Link
            style={{
              display: "block",
            }}
            to="/products-stats"
          >
            <Button minW={"5.5rem"} colorScheme="blue" variant={"link"}>
              Products <Spacer /> <ArrowForwardIcon />
            </Button>
          </Link>
          <ProductsFilter />
        </Flex>
      </SimpleGrid>
    </>
  );
}

export default Stats;
